@import '~antd/dist/antd.css';

.example::-webkit-scrollbar {
  display: none;
}

.ant-collapse-content {
  width: fit-content;
}

.ant-carousel .slick-dots-bottom > li > button {
    background-color: #364d79;
  }

.ant-carousel .slick-dots-bottom > li.slick-active > button {
    background-color: #364d79;
  }
  
.slick-slide > div > div {
  display: flex !important;
}

